import React from 'react';
import Image from 'next/image';

import classes from './Advantages.module.scss';

export interface Advantage {
  iconSrc: string;
  text: string;
}

interface Props {
  items: Advantage[];
}

export const Advantages: React.FunctionComponent<Props> = ({ items }) => {
  return (
    <div className={classes.row}>
      {items.map((item, index) => (
        <div className={classes.item} key={index}>
          <div className={classes.icon}>
            <Image priority src={item.iconSrc} alt="" />
          </div>
          <div
            className={classes.name}
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </div>
      ))}
    </div>
  );
};
