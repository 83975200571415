import { create } from 'zustand';

export interface CookieConsents {
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
}

interface GlobalSettingsStoreData {
  cookieConsents: CookieConsents;
  setCookieConsents: (cookieConsents: CookieConsents) => void;
}

const defaultConsents = {
  marketing: false,
  necessary: false,
  preferences: false,
  statistics: false,
};

export const useGlobalSettingsStore = create<GlobalSettingsStoreData>(
  (set) => ({
    cookieConsents: defaultConsents,
    setCookieConsents: (cookieConsents) => set({ cookieConsents }),
  }),
);
