import React, { FC, Fragment, ReactNode, Suspense, useEffect } from 'react';
import dynamic from 'next/dynamic';
import useCookie from 'react-use/lib/useCookie';
import useTranslation from 'next-translate/useTranslation';

import { Locale } from '../../types/Locale';
import ConfigsContext from '../../contexts/configs';
import { ClientSideConfigs } from '../../clientSideConfigs';
import { HOME_ROUTE, NEXT_LOCALE_COOKIE_NAME } from '../../constants';
import { I18nContext, I18nContextProps } from '../../contexts/i18n';
import { initGTM, setGTMListeners } from '../../helpers/googleTagManager';
import {
  CookieConsents,
  useGlobalSettingsStore,
} from '../../contexts/globalSettings';
import { ZaufaneContext } from '../../contexts/zaufane';
import { SEOProps } from '../SEO';
import { NextJsI18nConfig } from '../../types/I18nProps';

import classes from './index.module.scss';

import Ribbon from '@/components/Ribbon';
import { DynamicRoutesMap } from '@/types/DynamicRoutesMap';

const MediaContextProvider = dynamic(() => import('@/contexts/media'));
const CookieBot = dynamic(
  () => import('../forked/react-cookiebot/react-cookiebot'),
);
const Navbar = dynamic(() => import('../Navbar'));
const Footer = dynamic(() => import('../Footer'));
const SEO = dynamic(() => import('../SEO'));
const AdminBar = dynamic(() => import('./AdminBar'), { ssr: false });

type PageUiProps = {
  children: ReactNode;
  contentAboveChildren: ReactNode;
  contentBelowChildren: ReactNode;
  i18nContextProps: I18nContextProps;
} & Pick<Props, 'configs' | 'rating' | 'disableMediaContext'>;

const PageUiWrapper: FC<PageUiProps> = ({
  children,
  contentAboveChildren,
  contentBelowChildren,
  i18nContextProps,
  configs,
  rating,
  disableMediaContext,
}) => {
  const cookieConsents = useGlobalSettingsStore(
    (store) => store.cookieConsents,
  );

  const { lang } = useTranslation();

  const [, setCookie] = useCookie(NEXT_LOCALE_COOKIE_NAME);

  useEffect(() => {
    if (cookieConsents.necessary) {
      setCookie(lang, {
        path: HOME_ROUTE,
      });
    }
  }, [setCookie, lang, cookieConsents]);

  const MediaContext = disableMediaContext ? Fragment : MediaContextProvider;

  return (
    <ConfigsContext.Provider value={configs}>
      <I18nContext.Provider value={i18nContextProps}>
        <ZaufaneContext.Provider value={rating ?? null}>
          {contentAboveChildren}
          <MediaContext>{children}</MediaContext>
          {contentBelowChildren}
        </ZaufaneContext.Provider>
      </I18nContext.Provider>
    </ConfigsContext.Provider>
  );
};

interface Props
  extends Pick<
    SEOProps,
    | 'title'
    | 'description'
    | 'socialMediaImagePath'
    | 'rating'
    | 'withoutAggregateRatingLdJson'
  > {
  _i18nConfig: NextJsI18nConfig;
  configs: ClientSideConfigs;
  description?: string;
  title?: string;
  transparentNavbar?: boolean;
  hideUI?: boolean;
  simpleUI?: boolean;
  ribbon?: string;
  withoutFixedNavbar?: boolean;
  hideSimpleUiLocaleChanger?: boolean;
  hideFooter?: boolean;
  dynamicRoutesLocaleMap?: DynamicRoutesMap;
  isPreviewMode?: boolean;
  children: ReactNode;
  disableMediaContext?: boolean;
}

const GTM_INIT_TIMEOUT = 7000;

declare global {
  interface Window {
    Cookiebot?: { consent: CookieConsents };
  }
}

const Page: React.FunctionComponent<Props> = ({
  _i18nConfig,
  children,
  configs,
  description,
  title,
  socialMediaImagePath,
  transparentNavbar,
  hideUI,
  simpleUI,
  rating,
  ribbon,
  withoutAggregateRatingLdJson,
  withoutFixedNavbar = false,
  hideSimpleUiLocaleChanger = false,
  hideFooter = false,
  dynamicRoutesLocaleMap,
  isPreviewMode,
  disableMediaContext,
}) => {
  const domains = _i18nConfig?.domains ?? [];
  const locales = _i18nConfig?.locales ?? [];

  const cookiebotId = configs?.services?.cookiebotId;
  const googleTagManager = configs?.services?.googleTagManager;

  const { lang } = useTranslation();

  useEffect(() => {
    if (!googleTagManager) return;

    setTimeout(
      () => initGTM(googleTagManager.host, googleTagManager.trackingId),
      GTM_INIT_TIMEOUT,
    );
    setGTMListeners(googleTagManager.host, googleTagManager.trackingId);
  }, [googleTagManager]);

  const i18nContextProps: I18nContextProps = {
    currentLocale: lang as Locale,
    domains,
    locales,
    dynamicRoutesLocaleMap,
  };

  return (
    <PageUiWrapper
      i18nContextProps={i18nContextProps}
      rating={rating}
      configs={configs}
      disableMediaContext={disableMediaContext}
      contentAboveChildren={
        <>
          <Suspense>
            {cookiebotId && (
              <CookieBot domainGroupId={cookiebotId} solution={'eu'} />
            )}
          </Suspense>
          <Suspense>
            <SEO
              title={title}
              description={description}
              socialMediaImagePath={socialMediaImagePath}
              rating={rating}
              withoutAggregateRatingLdJson={withoutAggregateRatingLdJson}
            />
          </Suspense>
          <Suspense>
            {!hideUI && (
              <Navbar
                simpleUI={simpleUI}
                transparentOnTop={transparentNavbar}
                withoutFixedNavbar={withoutFixedNavbar}
                hideSimpleUiLocaleChanger={hideSimpleUiLocaleChanger}
              />
            )}
            {!hideUI && !transparentNavbar && !withoutFixedNavbar && (
              <div className={classes.navbarClearfix} />
            )}
            {!!ribbon && <Ribbon>{ribbon}</Ribbon>}
          </Suspense>
        </>
      }
      contentBelowChildren={
        <>
          <Suspense>
            {isPreviewMode && <AdminBar isPreview={isPreviewMode} />}
          </Suspense>
          <Suspense>
            {!(hideFooter || hideUI) && <Footer simpleUI={simpleUI} />}
          </Suspense>
        </>
      }
    >
      {children}
    </PageUiWrapper>
  );
};

export default Page;
