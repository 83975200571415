import classes from './index.module.scss';

const Ribbon: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => (
  <div className={classes.ribbonWrapper}>
    <div className={classes.ribbon}>{children}</div>
  </div>
);

export default Ribbon;
