import { ImageProps } from 'next/image';
import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import Image from 'next/image';

import classes from './index.module.scss';

import { MaxWidthBreakpointName } from '@/styles/constants/breakpoints/types';

interface EllipseClipOptions {
  side: 'top' | 'bottom';
  size: 's' | 'l';
}

export type SectionBackgroundColor = 'white' | 'gray' | 'dark';

type SectionBackgroundColorWithCustomInput =
  | SectionBackgroundColor
  | Omit<string, SectionBackgroundColor>;

enum AdditionalBreakpointName {
  DefaultDesktop = 'DefaultDesktop',
}

type SettingParamName = MaxWidthBreakpointName | AdditionalBreakpointName;

type ContentPaddingBottomSettings = Partial<Record<SettingParamName, string>>;

interface Props {
  children?: ReactNode;
  addNavbarPadding?: boolean;
  backgroundColor?: SectionBackgroundColorWithCustomInput;
  backgroundImage?: {
    src: ImageProps['src'];
    alt: string;
    quality?: number;
    sizes?: string;
    style?: React.CSSProperties;
  };
  backgroundCustom?: React.ReactNode;
  disableContentPaddingTop?: boolean;
  disableBorderBottom?: boolean;
  contentPaddingBottomSettings?: ContentPaddingBottomSettings;
  sectionType?: 'header' | 'default';
  elementsAfterContent?: React.ReactNode;
  className?: string;
  fullscreen?: boolean;
  alignContentCenter?: boolean;
  ellipseClip?: EllipseClipOptions;
  id?: string;
  disableContentPaddingHorizontal?: boolean;
}

interface SectionHeaderProps {
  noMargin?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  children?: ReactNode;
  className?: string;
}

export const SectionHeader: FC<SectionHeaderProps> = ({
  children,
  className,
  noMargin = false,
  textAlign = 'left',
}) => {
  return (
    <header
      className={clsx(
        classes.sectionHeader,
        classes[`sectionHeader_${textAlign}`],
        noMargin && classes.sectionHeader_noMargin,
        className,
      )}
    >
      {children}
    </header>
  );
};

type SectionContentProps = {
  children?: ReactNode;
} & Pick<
  Props,
  | 'contentPaddingBottomSettings'
  | 'addNavbarPadding'
  | 'disableContentPaddingTop'
  | 'disableContentPaddingHorizontal'
>;

export const SectionContent: FC<SectionContentProps> = ({
  contentPaddingBottomSettings = {},
  addNavbarPadding = false,
  disableContentPaddingTop = false,
  disableContentPaddingHorizontal = false,
  children,
}) => {
  return (
    <div
      className={clsx(
        classes.sectionContent,
        {
          [classes.sectionContent_addNavbarPadding]: addNavbarPadding,
          [classes.sectionContent_disableContentPaddingTop]:
            disableContentPaddingTop,
          [classes.sectionContent_disableContentPaddingHorizontal]:
            disableContentPaddingHorizontal,
        },
        Object.entries(contentPaddingBottomSettings).map(([bpName, value]) => {
          if (!value) return null;
          return classes[`sectionContent_paddingBottom_${bpName}`];
        }),
      )}
      style={Object.entries(contentPaddingBottomSettings).reduce(
        (acc, [bpName, value]) => {
          if (!value) return acc;
          return {
            ...acc,
            [`--paddingBottom${bpName}`]: value,
          };
        },
        {},
      )}
    >
      {children}
    </div>
  );
};

export const Section: React.FunctionComponent<Props> = ({
  addNavbarPadding = false,
  backgroundColor = 'white',
  backgroundCustom,
  backgroundImage,
  disableContentPaddingTop = false,
  disableContentPaddingHorizontal = false,
  disableBorderBottom = false,
  contentPaddingBottomSettings = {},
  sectionType = 'default',
  children,
  elementsAfterContent,
  className,
  fullscreen = false,
  alignContentCenter = false,
  ellipseClip = undefined,
  id,
}) => {
  const isBackgroundImagePrioritized = sectionType === 'header';

  return (
    <section
      className={clsx(
        classes.section,
        classes[`section_${backgroundColor}`],
        {
          [classes.section_disableBorderBottom]: disableBorderBottom,
          [classes.section_fullScreen]: fullscreen,
          [classes.section_alignContentCenter]: alignContentCenter,
          [classes.section_withBackgroundImage]: !!backgroundImage,
        },
        ellipseClip &&
          classes[`ellipseClip_${ellipseClip.side}_${ellipseClip.size}`],
        className,
      )}
      id={id}
    >
      {backgroundImage && (
        <div
          className={clsx(
            classes.sectionBgImageWrapper,
            ellipseClip &&
              classes[`ellipseClip_${ellipseClip.side}_${ellipseClip.size}`],
          )}
        >
          <Image
            priority={isBackgroundImagePrioritized}
            src={backgroundImage.src}
            alt={backgroundImage.alt ?? ''}
            loading={isBackgroundImagePrioritized ? undefined : 'lazy'}
            sizes={backgroundImage.sizes ?? '100vw'}
            fill
            style={{
              objectFit: 'cover',
              objectPosition: backgroundImage.style?.objectPosition || 'center',
            }}
            quality={backgroundImage.quality}
          />
        </div>
      )}
      {backgroundCustom && (
        <div
          className={clsx(
            classes.sectionBgImageWrapper,
            ellipseClip &&
              classes[`ellipseClip_${ellipseClip.side}_${ellipseClip.size}`],
          )}
        >
          {backgroundCustom}
        </div>
      )}
      <SectionContent
        addNavbarPadding={addNavbarPadding}
        contentPaddingBottomSettings={contentPaddingBottomSettings}
        disableContentPaddingHorizontal={disableContentPaddingHorizontal}
        disableContentPaddingTop={disableContentPaddingTop}
      >
        {children}
      </SectionContent>
      {elementsAfterContent}
    </section>
  );
};
