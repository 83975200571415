import React from 'react';

import { ClientSideConfigs } from '../../clientSideConfigs';

const ConfigsContext = React.createContext<ClientSideConfigs>({
  announcements: { announcements: [] },
  contactTopics: [],
  benefits: [],
  blog: {
    url: '',
    apiBlogTag: 0,
    pressReleasesBlogCategory: 0,
    partnersBlogCategory: 0,
  },
  caseStudies: [],
  clients: [],
  contact: {
    sendLimit: 0,
    phoneNumber: '',
    companyInfo: {
      items: [],
    },
  },
  corporateContact: {
    contactPoints: [],
    logo: '',
    image: '',
    url: '',
  },
  departments: [],
  distinctiveFeatures: [],
  externalUrls: {
    docs: '',
    panel: '',
    blog: '',
    blogPressReleases: '',
    docsShortcuts: {},
  },
  features: {
    isCountrySelectInLandingPage: false,
    showPasswordSmsInformationCard: false,
    hlrInPricing: false,
    intercomChatWithUsButtonInPricing: false,
    newsletterSmsAdditionalInfoCards: false,
    showSimilarPagesGroupOfSmsCampaign: false,
    showSimilarPagesGroupOfSms: false,
    showIntegrationsInSmsNotifications: false,
    showEncouragementSectionInSmsNotifications: false,
    showBulkSmsTestimonialsSection: false,
    showVirtualMobileNumbersInPricing: false,
    showExclVATSuffixInPricing: false,
    isNewsletterButtonVisibleInFooter: false,
    isSignUpButtonVisibleInPricingHero: false,
    isIndividualOfferButtonVisible: false,
    showSeSmsServicesForBusinessesInHome: false,
    showArticlesInHome: false,
    showIntegrationsInHome: false,
    showPressReleases: false,
    showIso27001: false,
    showDataProcessingPdf: false,
    showPayUIconOnLandingPagePayments: false,
    isRegistrationEnabled: true,
    showContactFaqSection: false,
  },
  footer: {
    cssGridAreasLayout: [],
    footerItems: [],
  },
  homeIntegrations: [],
  integrationsCategories: [],
  libraries: {
    official: [],
    unofficial: [],
  },
  localeChangerConfig: [],
  mediaAboutUs: {
    featured: [],
    home: [],
  },
  megalanding: {
    isEnabled: false,
    promoCode: '',
    sendRateMessage: false,
  },
  mobileMarketingTools: [],
  navbar: { navbarItems: [] },
  transformedNavbar: [],
  individualOffer: {
    email: {
      lowTraffic: '',
      highTraffic: '',
    },
    options: {
      messageCategory: [],
      monthlyQuantity: [],
    },
  },
  pricing: {
    countrySelectDefaultValue: '',
    pricesJsonEndpoint: '',
    paymentMethods: {
      bankTransfer: false,
      masterCard: false,
      swish: false,
      visa: false,
    },
    formsOfPayments: [],
    pricingTableSmsAmountSteps: [],
  },
  referralProgram: [],
  registration: {
    googleAnaliticsPageviewUrlCountryCodeMap: {},
  },
  reports: [],
  pressAttachments: [],
  routes: [],
  services: {
    cookiebotId: '',
    googleSiteVerification: [],
    googleTagManager: {
      host: 'https://www.googletagmanager.com',
      trackingId: '',
    },
    msvalidate01: '',
    recaptchaSiteKey: '',
    yandexVerification: '',
  },
  sidebar: {
    features: {
      label: '',
      subitems: [],
    },
  },
  smsFeatures: [],
  smsMarketing: {
    comments: [],
    efficiency: [],
    firstShipment: [],
    personalization: [],
    target: [],
    usage: [],
  },
  smsNotifications: {
    heroYouTubeVideoId: '',
    integrations: [],
  },
  smsTools: [],
  socialMedia: { icons: [] },
  socialProfile: {
    name: '',
    sameAs: [],
    type: 'Organization',
    url: '',
  },
  twoWayFAQ: [],
  videoIds: {
    receiveSms: '',
    twoWayCommunication: '',
    shortLink: '',
  },
  videoTutorial: {
    isVideoTitleTranslated: false,
    smsapiDiy: [],
    aboutSmsIn160s: [],
    marketingGuide: [],
  },
});

export default ConfigsContext;
