import React from 'react';

import { DynamicRoutesMap } from '@/types/DynamicRoutesMap';
import { Domain } from '@/types/Domain';
import { Locale } from '@/types/Locale';

export interface I18nContextProps {
  currentLocale: Locale;
  domains: Domain[];
  locales: Locale[];
  dynamicRoutesLocaleMap?: DynamicRoutesMap;
}

export const I18nContext = React.createContext<I18nContextProps>({
  currentLocale: 'en',
  domains: [],
  locales: [],
});
