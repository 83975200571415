import React, { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import Button from '../Button';

import { Advantage, Advantages } from './components/Advantages';
import classes from './index.module.scss';

import ConfigsContext from '@/contexts/configs';
import { pushToDataLayer } from '@/helpers/googleTagManager';

interface Props {
  header: string;
  subheading: string;
  advantages: Advantage[];
  className?: string;
}

export const HeroLeftSide: React.FunctionComponent<Props> = ({
  advantages,
  header,
  subheading,
  className,
}) => {
  const { t } = useTranslation();
  const { features } = useContext(ConfigsContext);
  const { asPath } = useRouter();

  return (
    <div className={clsx(classes.container, className)}>
      <h1
        className={classes.heroHeader}
        dangerouslySetInnerHTML={{ __html: header }}
      />
      <p
        className={classes.heroSubHeader}
        dangerouslySetInnerHTML={{ __html: subheading }}
      />
      <div className={classes.buttonRow}>
        {features.isRegistrationEnabled && (
          <Button
            label={t('common::Try for free')}
            href={'/registration'}
            buttonType="callToAction"
            onClick={() => {
              pushToDataLayer('btn_click', {
                pagename: asPath,
                button: 'registration',
                component: 'hero',
              });
            }}
          />
        )}
        {features.isIndividualOfferButtonVisible && (
          <Button
            buttonType="primary"
            label={t('common::Request an offer')}
            href={'/individual-offer'}
            icon={{ iconName: 'arrow', placeAfterLabel: true }}
            onClick={() => {
              pushToDataLayer('btn_click', {
                pagename: asPath,
                button: 'individual offer',
                component: 'hero',
              });
            }}
          />
        )}
      </div>
      <Advantages items={advantages} />
    </div>
  );
};
